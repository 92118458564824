<div class="footer">
  <mat-toolbar>
    <div class="footer__left">
      <div class="footer__section">
        <div class="footer__expand d__none d__flex--lg" (click)="toggleSidenav()">
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <div class="footer__section d__none d__flex--lg">
        <ng-container *ngTemplateOutlet="branch"></ng-container>
      </div>
      <div class="footer__section footer__section--flex-sb d__flex d__none--lg">
        <ng-container *ngTemplateOutlet="branch"></ng-container>
        <div>
          <a mat-icon-button *ngFor="let socialIcon of socialIcons" class="footer__social-icon" href="{{socialIcon.link}}" rel="noreferrer" target="_blank">
            <mat-icon svgIcon="{{socialIcon.icon}}"></mat-icon>
          </a>
        </div>
      </div>
      <div class="footer__section">
        <a class="footer__link" routerLinkActive="footer__link--active" routerLink="/privacy">Privacy Policy</a>
        <span class="footer__link-divider">|</span>
        <a class="footer__link" routerLinkActive="footer__link--active" routerLink="/terms">Terms & Conditions</a>
      </div>
    </div>
    <div class="footer__right">
      <div class="footer__section">
        <a class="footer__link footer__text--smaller" href="mailto:{{email}}">{{email}}</a>
      </div>
      <div class="footer__section">
        <mat-icon svgIcon="copyright" class="footer__text footer__copyright-icon"></mat-icon>
        <span class="footer__text footer__text--smaller">{{currentYear}}</span>
      </div>
    </div>
  </mat-toolbar>
</div>

<ng-template #branch>
  <a class="footer__link" href="https://github.com/datariomj/datariomj/tree/main" rel="noreferrer" target="_blank">
    <mat-icon svgIcon="git"></mat-icon>
    <span>main</span>
  </a>
</ng-template>