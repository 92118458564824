<mat-drawer-container class="sidenav" autosize>
  <mat-drawer #drawer class="d__none d__block--lg" fixedInViewport role="navigation" mode="side" opened="true" disableClose>
    <mat-nav-list>
      <ng-container *ngTemplateOutlet="mainNavigation"></ng-container>
    </mat-nav-list>
    <app-footer class="d__none d__block--lg"></app-footer>
  </mat-drawer>
  <div class="sidenav__route-wrapper">
    <ng-content></ng-content>
  </div>
  <app-footer class="d__none--lg"></app-footer>
  <mat-toolbar class="sidenav__mobile-nav d__flex d__none--lg">
    <mat-nav-list class="sidenav__mobile-nav-list">
      <ng-container *ngTemplateOutlet="mainNavigation"></ng-container>
    </mat-nav-list>
  </mat-toolbar>
</mat-drawer-container>

<ng-template #mainNavigation>
  <a mat-list-item *ngFor="let navLink of navLinks" class="sidenav__link" routerLinkActive="sidenav__link--active"
     [routerLink]="navLink.route" [routerLinkActiveOptions]="{exact: navLink.route === ''}">
    <div class="sidenav__link-wrapper">
      <mat-icon svgIcon="{{navLink.icon}}" class="sidenav__icon"></mat-icon>
      <p *ngIf="sidenavExpanded$ | async" class="sidenav__description d__none d__block--lg">{{navLink.description}}</p>
    </div>
  </a>
  <!-- <a mat-list-item class="sidenav__link" routerLinkActive="sidenav__link--active" (click)="openContactDialog()" data-cy="nav-contact">
    <div class="sidenav__link-wrapper">
      <mat-icon svgIcon="contact" class="sidenav__icon"></mat-icon>
      <p *ngIf="sidenavExpanded$ | async" class="sidenav__description d__none d__block--lg">Contact</p>
    </div>
  </a> -->
</ng-template>